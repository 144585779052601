import React from "react";

import { Row, Col } from "react-bootstrap";

export default function Footer() {
  return (
    <div className="footer-banner p-3">
      <Row className="d-flex justify-content-center p-3">
        <Row className="p-3 mt-3 mb-0 pb-0">
          <p>Created by Nathan Borgas 2023</p>
          <p className="credits">Made using React</p>
          <hr />
        </Row>
        <Row>
          <Col className="d-flex justify-content-center site-map">
            <a href="/">Home</a>
            <a href="/">About</a>
            <a href="/">Projects</a>
            <a href="/">Contact</a>
          </Col>
          <hr className="my-3" />
        </Row>
        <Row className="d-flex p-3">
          <Col className="footer-contact">
            <span>
              <strong className="me-2">Email:</strong> borgasnathan@gmail.com
            </span>
            <span>
              <strong className="me-2">Phone:</strong> 9999 9999
            </span>
            <span>
              <strong className="me-2">Location:</strong> Melbourne, Australia
            </span>
          </Col>
        </Row>
      </Row>
    </div>
  );
}
