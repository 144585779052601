import React from "react";

import { Row, Col, ListGroup, ListGroupItem, Image } from "react-bootstrap";

// Image
import CodeImage from "../assets/aboutme_code.png";
import ProfileImg from "../assets/aboutme_profile.png";

export default function AboutMe() {
  return (
    <Row className="border p-3 justify-content-center">
      <Col>
        <Row className="justify-content-center">
          <h4 className="subtitle section-heading">The Websites</h4>
          <hr />
        </Row>
        <Row className="d-flex justify-content-center">
          <ListGroup variant="flush" className="font-heading mb-3">
            <ListGroupItem>Modern website designs</ListGroupItem>
            <ListGroupItem>Dynamic functionality</ListGroupItem>
            <ListGroupItem>Robust Frameworks</ListGroupItem>
            <ListGroupItem>Simple to Sophisticated</ListGroupItem>
            <ListGroupItem>Front End</ListGroupItem>
            <ListGroupItem>Back End</ListGroupItem>
            <ListGroupItem>Interactive Features</ListGroupItem>
          </ListGroup>
        </Row>
        <Row className="justify-content-center">
          <h4 className="subtitle section-heading">The Man</h4>
          <hr />
        </Row>
        <Row className="d-flex justify-content-around text-start p-3">
          <Row className="justify-content-center align-items-center">
            <Col>
              <ul className="ms-3 fw-light">
                <li>Goals & Teamwork Oriented</li>
                <li>Creative Constructions</li>
                <li>Fast Learner</li>
                <li>Open Minded</li>
                <li>Art / Music / Design</li>
                <li>Problem Solver</li>
              </ul>
            </Col>
            <Col className="align-items-center">
              <Image
                src={ProfileImg}
                alt="Profile Photo of Nathan Borgas"
                className="profile-img align-self-center mx-auto border"
              ></Image>
            </Col>
          </Row>
          <Row className="p-3">
            <p className="subtitle text-muted text-center">
              Pure, organic free-range code. No AI additives.
            </p>
            <p className="border fw-light">
              I am a Front-End Developer from{" "}
              <strong>Melbourne, Australia</strong> specializing in functional,
              modern websites. Currently completing my Diploma in Information
              Technology (Web Development) at Holmesglen TAFE.
            </p>
            <p className="border fw-light">
              I excel both working with teams and solo, and am currently{" "}
              <strong>open </strong>
              for employment opportunities or freelance work.
            </p>
          </Row>
        </Row>
      </Col>
      <Col>
        <Row className="justify-content-center">
          <h4 className="subtitle">Skills</h4>
          <hr />
        </Row>
        <Row className="d-flex mb-2">
          <div className="skill-div">HTML</div>
          <div className="skill-div">CSS</div>
          <div className="skill-div">Javascript</div>
          <div className="skill-div">PHP</div>
          <div className="skill-div">React</div>
          <div className="skill-div">Sass</div>
          <div className="skill-div">SQL</div>
          <div className="secondary-skill-div">Bootstrap</div>
          <div className="secondary-skill-div">Wireframing</div>
          <div className="secondary-skill-div">Graphic Design</div>
        </Row>
        <Row>
          <Image src={CodeImage} alt="coding icon"></Image>
        </Row>
      </Col>
    </Row>
  );
}
