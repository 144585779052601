import React from "react";
import { Row, Container } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";

// Pages
import Projects from "../pages/Projects";
import Contact from "../pages/Contact";
import AboutMe from "../pages/AboutMe";
import PageNotFound from "../pages/PageNotFound";

export default function ContentSectionDiv() {
  return (
    <React.Fragment>
      <a id="content-anchor" className="d-none" href="/">
        Anchor
      </a>
      <Container>
        <Row className="contentDiv rounded-1 border border-danger p-3 justify-content-center">
          <Routes>
            <Route path="/" element={<Projects />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/about" element={<AboutMe />}></Route>
            <Route path="*" element={<PageNotFound />}></Route>
          </Routes>
        </Row>
      </Container>
    </React.Fragment>
  );
}
