import React from "react";

import { Container, Row, Col, Card, Button, Image } from "react-bootstrap";

// Project Images
import petRankImg from "../assets/projectimg_petrank.jpg";
import catConverterImg from "../assets/projectimg_catconverter.jpg";
import htourismImg from "../assets/projectimg_htourism.jpg";
import portfolioImg from "../assets/projectimg_portfolio.jpg";

// Artwork Images
import YuriBPainting from "../assets/projects_yuribpainting.jpg";
import CaesarPainting from "../assets/projects_caesarpainting.png";

export default function Projects() {
  return (
    <Container className="p-3 justify-content-center border">
      {/* <Col className="d-flex justify-content-center align-items-center">
        <span className="inactive me-3" id="inactiveLink">
          About
        </span>
        <h4 className="subtitle activeLink">Projects</h4>
        <span className="inactive ms-3" id="inactiveLink">
          Contact
        </span>
      </Col>
      <hr className="w-75 mx-auto"></hr> */}
      <Row className="justify-content-center">
        {/* Projects Title */}
        <Row className="d-flex justify-content-center">
          <h4 className="subtitle section-heading">Projects</h4>
          <hr className="text-center" />
        </Row>
        {/* Description */}
        <Row className="d-flex justify-content-center">
          <ul className="d-flex project-list-description justify-content-around">
            <li>A list of projects throughout my education and career.</li>
            <li className="text-muted">
              Paid work will <i>never</i> be added unless approved by client.
            </li>
          </ul>
          {/* <p>A list of projects throughout my education and career.</p>
          <p className="text-muted">
            Contracted work will never be added to this list unless approved by
            client.
          </p> */}
        </Row>
        {/* Websites Title */}
        <Row className="d-flex justify-content-center">
          <hr className="text-center mb-3" />
          <h4 className="subtitle section-heading">Websites</h4>
          <hr className="text-center mt-2" />
        </Row>
        <Row className="pb-3">
          <Col>
            {/* CARD 1 */}
            <Card
              style={{ width: "18rem" }}
              className="project-card shadow-lg rounded-0 p-3"
            >
              <Card.Header className="card-header">
                2022 - Holmesglen
              </Card.Header>
              <Card.Img
                variant="top"
                src={petRankImg}
                className="card-img rounded-0"
              />
              <Card.Body className="p-0 border border-top-0">
                <Card.Title className="card-title p-2">Pet Rank</Card.Title>
                <Card.Text className="card-description pt-2">
                  Compete against other pets to reach the number one rank!
                </Card.Text>
                <Button
                  className="rounded-0 w-100 btn-gotoprojects"
                  href="https://github.com/NathanBorgas/PetRank"
                  target="_blank"
                >
                  View Project
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            {/* CARD 2 */}
            <Card
              style={{ width: "18rem" }}
              className="project-card shadow-lg rounded-0 p-3"
            >
              <Card.Header className="card-header">
                2022 - Holmesglen
              </Card.Header>
              <Card.Img
                variant="top"
                src={catConverterImg}
                className="card-img rounded-0"
              />
              <Card.Body className="p-0 border border-top-0">
                <Card.Title className="card-title p-2">
                  Cat Converter
                </Card.Title>
                <Card.Text className="card-description pt-2">
                  Who would you be if you were a cat? Simply enter your name and
                  find out.
                </Card.Text>
                <Button
                  className="rounded-0 w-100 btn-gotoprojects"
                  href="https://github.com/NathanBorgas/Cat-Converter"
                  target="_blank"
                >
                  View Project
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            {/* CARD 3 */}
            <Card
              style={{ width: "18rem" }}
              className="project-card shadow-lg rounded-0 p-3"
            >
              <Card.Header className="card-header">
                2022 - Educational
              </Card.Header>
              <Card.Img
                variant="top"
                src={htourismImg}
                className="card-img rounded-0"
              />
              <Card.Body className="p-0 border border-top-0">
                <Card.Title className="card-title p-2">
                  Hokkaido Tourism
                </Card.Title>
                <Card.Text className="card-description pt-2">
                  Hokkaido Tourism is Japan's number one fake tourism business.
                </Card.Text>
                <Button
                  className="rounded-0 w-100 btn-gotoprojects"
                  href="https://github.com/NathanBorgas"
                  target="_blank"
                >
                  View Project
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            {/* CARD 4 */}
            <Card
              style={{ width: "18rem" }}
              className="project-card shadow-lg rounded-0 p-3"
            >
              <Card.Header className="card-header">
                2023 - Educational
              </Card.Header>
              <Card.Img
                variant="top"
                src={portfolioImg}
                className="card-img rounded-0"
              />
              <Card.Body className="p-0 border border-top-0">
                <Card.Title className="card-title p-2">
                  Portfolio Placeholder
                </Card.Title>
                <Card.Text className="card-description pt-2">
                  Ever wondered what this site looked like early in development?
                </Card.Text>
                <Button
                  className="rounded-0 w-100 btn-gotoprojects"
                  href="https://github.com/NathanBorgas"
                  target="_blank"
                >
                  View Project
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* Artwork Section */}
        <Row className="d-flex justify-content-center">
          <hr className="text-center mb-3" />
          <h4 className="subtitle section-heading">Artwork</h4>
          <hr className="text-center mt-2" />
        </Row>
        {/* Yuri B Painting 01*/}
        <Row className="d-flex justify-content-center mb-3">
          <Col>
            <Image
              src={YuriBPainting}
              alt="Yuri B Knight Painting Pixabay"
              className="artwork-img border border-danger"
            ></Image>
          </Col>
          <Col>
            <Row className="contentDiv rounded-1 border border-danger p-3 justify-content-center w-100 h-100 shadow align-items-top">
              <Row className="align-items-top">
                <h2 className="subtitle fw-light">
                  <span className="text-danger me-3 fw-lighter">
                    <i>Yuri B</i>
                  </span>{" "}
                  Moon Knight
                  <hr />
                </h2>
              </Row>
              <Row>
                <p>
                  Clearly this a placeholder image that will eventually be
                  replaced by my own artwork in the future, as well as this text
                  which I am currently typing with no purpose at all except to
                  fill out content and make this website look more complete and
                  filled out. A couple more words should do the trick.
                </p>
              </Row>
              <Row className="justify-content-center">
                <span className="skill-div">Oil</span>
                <span className="skill-div">Painting</span>
                <span className="skill-div">Atmospheric</span>
              </Row>
            </Row>
          </Col>
        </Row>

        <hr />

        {/* Caesar Painting 02 */}
        <Row className="d-flex justify-content-center mb-3">
          <Col>
            <Image
              src={CaesarPainting}
              alt="Julius Caesar Painting Pixabay"
              className="artwork-img border border-danger"
            ></Image>
          </Col>
          <Col>
            <Row className="contentDiv rounded-1 border border-danger p-3 justify-content-center w-100 h-100 shadow align-items-top">
              <Row className="align-items-top">
                <h2 className="subtitle fw-light">
                  <span className="text-danger me-3 fw-lighter">
                    <i>Julius Caesar</i>
                  </span>{" "}
                  Brutus
                  <hr />
                </h2>
              </Row>
              <Row>
                <p>
                  Once again I am back at it again with a plethora of worthless
                  words to fill out content. I got this image from Pixabay but
                  I'm pretty sure the copyright would have ran out for the Roman
                  Empire at this point. Either way, a cool painting and maybe
                  one day I can put something of my own of this quality on this
                  website.
                </p>
              </Row>
              <Row className="justify-content-center">
                <span className="skill-div">Antique</span>
                <span className="skill-div">Historical</span>
                <span className="skill-div">Roman</span>
                <span className="skill-div">Assassination</span>
              </Row>
            </Row>
          </Col>
        </Row>
        {/* Thank You Message */}
        <Row className="justify-content-center align-items-center py-3">
          <hr />
          <p className="pt-3 pb-0 mb-0 text-muted fw-light">
            <span className="text-danger">Thank you</span> for viewing my
            projects. If you are interested in any further information, please
            visit the <span className="text-danger">Contact</span> page.
          </p>
        </Row>
      </Row>
    </Container>
  );
}
