import React from "react";

import { Row, Col, Form, Button, Image } from "react-bootstrap";

// Icons
import { MdEmail, MdLocalPhone, MdLocationPin } from "react-icons/md";

// External Link Logos
import GitHubLogo from "../assets/GitHub_Logo.png";

export default function Contact() {
  return (
    <div>
      <Col className="border p-3">
        <Row className="justify-content-center">
          <h4 className="subtitle section-heading">Contact</h4>
          <hr />
        </Row>
        <Row className="justify-content-center text-start">
          <Col>
            <ul className="bullet-list">
              <li>
                For enquiries about job offers or employment, please contact
                borgasnathan@gmail.com
              </li>
              <li>
                Business phone can be contacted from 8am - 5pm Mon - Friday.
              </li>
              <li>CV & Resume will be attached to this page in the future.</li>
              <li>Use the contact form to the right to get in touch.</li>
              <li>
                Please specify your preferred method of responses in the contact
                form.
              </li>
            </ul>
          </Col>
          <Col>
            <Form className="border rounded p-3">
              <Form.Group className="mb-3">
                <Form.Label className="my-1 text-muted">
                  Email address
                </Form.Label>
                <Form.Control type="email" placeholder="Enter email" />
                <Form.Label className="my-1 text-muted">
                  Enter Subject
                </Form.Label>
                <Form.Control type="text" placeholder="Enter subject" />
                <Form.Label className="my-1 text-muted">
                  Enter Content
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter Content"
                ></Form.Control>
                <Button className="bg-danger border-0 rounded-0 w-100">
                  Submit Enquiry
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-around font-heading pt-3 mt-3">
          {/* Email */}
          <Col className="d-flex justify-content-center align-items-center text-center">
            <MdEmail className="display-2 text-dark"></MdEmail>
            <span>
              <span className="mx-2 fw-bold">Email: </span>
              <span>borgasnathan@gmail.com</span>
            </span>
          </Col>
          {/* Phone */}
          <Col className="d-flex justify-content-center align-items-center text-center">
            <MdLocalPhone className="display-2 text-dark"></MdLocalPhone>
            <span>
              <span className="mx-2 fw-bold">Phone: </span>
              <span>9999 9999</span>
            </span>
          </Col>
          {/* Location */}
          <Col className="d-flex justify-content-center align-items-center text-center">
            <MdLocationPin className="display-2 text-dark"></MdLocationPin>
            <span>
              <span className="mx-2 fw-bold">Location: </span>
              <span className="fw-bold">Melbourne,</span>{" "}
              <span className="fw-bolder text-danger fs-5 ms-1">Australia</span>
            </span>
          </Col>
        </Row>
        <Row className="d-flex py-4 mt-3 justify-content-center">
          <Col className="d-flex justify-content-center">
            <a
              href="https://github.com/NathanBorgas"
              rel="noreferrer"
              target="_blank"
              className="w-25 mx-auto"
            >
              <Image
                src={GitHubLogo}
                alt="Github Logo Link"
                className="socials-link"
              ></Image>
            </a>
          </Col>
        </Row>
      </Col>
    </div>
  );
}
