import React from "react";

import { Image } from "react-bootstrap";
import PageNotFoundImage from "../assets/aboutme_code.png";

export default function PageNotFound() {
  return (
    <div>
      <h1 className="text-danger display-1 fw-bold mb-0 pb-0">404</h1>
      <h1 className="mt-0 pt-0">Page Not Found!</h1>
      <Image src={PageNotFoundImage} alt="coding icon"></Image>
      <h5 className="text-muted">
        Return to the home page or report this issue to the admin!
      </h5>
    </div>
  );
}
