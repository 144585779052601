import React, { useState } from "react";
import { Col } from "react-bootstrap";

import { Link } from "react-router-dom";

// Icons
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";

const linkList = ["/about", "/", "/contact"];

export default function ContentSectionNav() {
  const [linkIndex, setLinkIndex] = useState(1);

  function handlePageChangeBack() {
    if (linkIndex > 0) {
      setLinkIndex(linkIndex - 1);
      //   console.log(linkIndex);
    }
  }

  function handlePageChangeForward() {
    if (linkIndex < 2) {
      setLinkIndex(linkIndex + 1);
      //   console.log(linkIndex);
    }
  }

  return (
    <div className="p-3 pb-0">
      <Col className="d-flex justify-content-center align-items-center">
        {/* Left Arrow - Start */}

        <Link to={linkList[linkIndex - 1]} onClick={handlePageChangeBack}>
          <BsFillArrowLeftCircleFill
            className={
              "display-6 mx-3 navArrows " +
              (linkIndex === 0 ? "btn-hidden" : "")
            }
          />
        </Link>

        {/* Left Arrow - End */}

        <span
          className="inactive mx-3"
          id={linkIndex === 0 ? "activeLink" : "inactiveLink"}
        >
          <Link
            to="/about"
            onClick={() => {
              setLinkIndex(0);
            }}
          >
            About
          </Link>
        </span>
        <span
          className="inactive mx-3"
          id={linkIndex === 1 ? "activeLink" : "inactiveLink"}
        >
          <Link
            to="/"
            onClick={() => {
              setLinkIndex(1);
            }}
          >
            Projects
          </Link>
        </span>
        <span
          className="inactive mx-3"
          id={linkIndex === 2 ? "activeLink" : "inactiveLink"}
        >
          <Link
            to="/contact"
            onClick={() => {
              setLinkIndex(2);
            }}
          >
            Contact
          </Link>
        </span>

        {/* Right Arrow - Start */}
        <Link to={linkList[linkIndex + 1]} onClick={handlePageChangeForward}>
          <BsFillArrowRightCircleFill
            className={
              "display-6 mx-3 navArrows " +
              (linkIndex === 2 ? "btn-hidden" : "")
            }
          />
        </Link>
        {/* Right Arrow - End */}
      </Col>
      <hr className="w-75 mx-auto"></hr>
    </div>
  );
}
