import './App.css';

import './index.css';

import { BrowserRouter } from 'react-router-dom';

// Components
import Navibar from './components/NaviBar';
import ContentSectionDiv from './components/ContentSectionDiv';
import ContentSectionNav from './components/ContentSectionNav';
import Footer from './components/Footer';
// Pages
import Home from './pages/Home';



function App() {
  
  return (
    <div className="App">
      <BrowserRouter>
      <header>
        <Navibar></Navibar>
      </header>
      <Home></Home>
      <ContentSectionNav></ContentSectionNav>
      <ContentSectionDiv>
      </ContentSectionDiv>
      </BrowserRouter>
      <Footer></Footer>
    </div>
  );
}

export default App;
