import React from "react";

import { HashLink as Link } from "react-router-hash-link";

import { Container, Nav, Navbar, Image } from "react-bootstrap";

// Logo
import NBLogo from "../assets/NB_Logo.svg";

export default function NaviBar() {
  return (
    <Navbar variant="light" className="navbarlight shadow py-0">
      <Container>
        <Navbar.Brand href="#home">
          <Image
            src={NBLogo}
            alt="Nathan Borgas Logo"
            className="fluid nb-logo p-0"
          ></Image>
        </Navbar.Brand>
        <Nav className="justify-content-end">
          <Nav.Link as={Link} to="/about#content-anchor" className="navlinks">
            About Me
          </Nav.Link>
          <Nav.Link as={Link} to="/#content-anchor" className="navlinks">
            Projects
          </Nav.Link>
          <Nav.Link as={Link} to="/contact#content-anchor" className="navlinks">
            Contact
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
}
