import React from "react";
import bannerImg from "../assets/PortfolioBanner.svg";

export default function SeperatorBanner() {
  return (
    <div>
      <img
        src={bannerImg}
        alt="Banner Separator"
        className="seperator-banner"
      ></img>
    </div>
  );
}
