import React from "react";

import { Container, Button, Row, Col } from "react-bootstrap";

import { HashLink as Link } from "react-router-hash-link";

import SeperatorBanner from "../components/SeperatorBanner";

export default function Home() {
  return (
    <Container className="w-100 mx-auto">
      <div className="d-flex hero-img align-items-center justify-content-center">
        <Col>
          <Row>
            <h1 className="mb-0">Nathan Borgas</h1>
            {/* <h1 className="text-danger power">POWER</h1> */}
          </Row>
          <Row>
            <Button
              as={Link}
              to="/#content-anchor"
              variant="primary"
              className="btn-gotoprojects mx-auto shadow"
            >
              Go to Projects
            </Button>{" "}
          </Row>
        </Col>
      </div>
      <SeperatorBanner />
      {/* <ContentSectionNav></ContentSectionNav> */}
    </Container>
  );
}
